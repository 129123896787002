//Packages
import React from 'react'
import ReactDOM from 'react-dom'
//import {GoogleLogin, GoogleOAuthProvider, googleLogout} from '@react-oauth/google';
import robotLogo from "../logo519.png";
import jwt_decode from "jwt-decode";

 // Handles a successfull login from the google account service
 const loginSuccess = (res) => {
    //console.log('login success from:' , res.profileObj)

    //Update local storage to have google id and google name
    //var profile = res.profileObj;
    localStorage.setItem('Name', res.name);
    localStorage.setItem('ID', res.sub);

    //Reload the page to move to the next page if 
    //the login actually worked. Could also forcefully redirect 
    console.log("normal");
    window.location.reload(false);
   }

   // Handles a failed login from the google account service by console logging 
  const loginFailure = (res) => {
    console.log('login failure:' , res)
  }

  // Delete local storage to logout
function onLogout(){
    window.localStorage.clear()
    window.location.reload(false);
  }

//Links dont allow the underline to be removed for visited, so these helper functions move you pages
function toAbout(){
  window.location.href = '/about';
}

function toRooms(){
  window.location.href = '/rooms';
}

//const testFunction = (res) => {
 // console.log(jwt_decode(res));
//}

/* Props 
    None
    Used for the top banner */
class Banner extends React.Component {
    render() {
        return <div id='banner'>
            { /*<div className="banner-title"><h1 className='banner-text'>Schedule Bot</h1></div>
            <span className="banner-filler"></span>
            <span className="banner-filler"></span> */}
            <img src={robotLogo} className='robotLogo' onClick={toRooms} alt='Press to go back to home' />
            {/* localStorage.getItem("ID") ? <h1 className='banner-text'><p onClick={toRooms}>Rooms</p></h1> : <span></span> */} 
            {/* <h1 className='banner-text'><p onClick={toAbout}>About</p></h1> */ }
            {/* localStorage.getItem("ID") ? <googleLogout
              clientId="859719169606-udri29lad40cjrnhrabuups4fujaivto.apps.googleusercontent.com"
              buttonText="Log Out"
              onLogoutSuccess={onLogout}
              className = "logOutButton"
            /> : <GoogleLogin
              clientId="859719169606-udri29lad40cjrnhrabuups4fujaivto.apps.googleusercontent.com"
              //buttonText="Log in with Google"
              onSuccess={loginSuccess}
              //onFailure={loginFailure}
              //cookiePolicy={'single_host_origin'}
              //className = "logInButton"
          />  */}

          {/* I understand this is insanely janky but this is how google interacts :(. It adds a function to the global 
              namespace that will then be called by data-callback. Because it needs a globaly namespaced function....
          */
          window.loginJWT = function (e) {loginSuccess(jwt_decode(e.credential))}
          }
          { localStorage.getItem("ID") ? null :  <div id="g_id_onload"
            data-client_id="859719169606-udri29lad40cjrnhrabuups4fujaivto.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="loginJWT"
            data-itp_support="true">
          </div>
          
          }
          { localStorage.getItem("ID") ? <button className="logoutBtn" onClick={onLogout}>Logout</button> : 
            <div class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left">
            </div> 
            
            
          }
            
            </div>
        

    }

}

export default Banner


