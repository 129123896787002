// Packages 
import React, { Component } from 'react';
import {useParams} from "react-router-dom";
//import {GoogleLogin, GoogleLogout} from 'react-google-login';
import jwt_decode from "jwt-decode";
// Components 
import Banner from './components/banner.js';


function LoginScreen(){
    // Reads the url to get the roomID 
    const { roomID } = useParams();

    // **OLD** Handles a successfull login from the google account service
    /*const loginSuccess = (res) => {
      //console.log('login success from:' , res.profileObj)
      
      //Update local storage to have google id and google name
      var profile = res.profileObj;
      localStorage.setItem('Name', profile.name);
      localStorage.setItem('ID', res.googleId);

      //Redirect to room after having logged in 
      window.location = "/room/" + roomID;
     }*/

      // Handles a successfull login from the google account service
    const loginRedirectSuccess = (res) => {
      //console.log('login success from:' , res.profileObj)
      
      //Update local storage to have google id and google name
      //var profile = res.profileObj;
      localStorage.setItem('Name', res.name);
      localStorage.setItem('ID', res.sub);
      console.log("redirect");
      //Redirect to room after having logged in 
      window.location = "/room/" + roomID;
      }
  
    // Handles a failed login from the google account service by console logging 
    const loginFailure = (res) => {
      console.log('login failure:' , res)
     }

      return (
        
        <div className="LoginRedirect">
          {/*<Banner></Banner>*/}
            <h1>Login Before going to room</h1> 
        
            {/*<GoogleLogin
              clientId="859719169606-udri29lad40cjrnhrabuups4fujaivto.apps.googleusercontent.com"
              buttonText="Log in with Google"
              onSuccess={loginSuccess}
              onFailure={loginFailure}
              cookiePolicy={'single_host_origin'}
      /> */ }
          {
          window.loginJWT = function (e) {loginRedirectSuccess(jwt_decode(e.credential))}
          }
          { localStorage.getItem("ID") ? null : <div id="g_id_onload"
            data-client_id="859719169606-udri29lad40cjrnhrabuups4fujaivto.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="loginJWT"
            data-itp_support="true">
          </div>
          }
          { 
            <div class="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left">
            </div> 
            
          }

        </div>

      );
}

export default LoginScreen;